<template>
  <div class="">
    
    <HeaderVue heading="Tags" class="mb-3">
      <template v-slot:buttons>
        <div class="d-flex align-items-between">
    <!-- <AddTagModal></AddTagModal> -->
          <AddCategory ref="addTagModal" :categoryTitle="{singular:'tag', plural:'tags'}" @submit="saveTags($event)" :isLoading="isLoadAddTags" ></AddCategory>
         
          <button v-if="getNavbarData.userType=='shopify'"
            class="btn ml-2 py-2 px-3 shopify-btn "
            @click="openShopifyTagModal()">
            import from <i class="fab fa-shopify"></i>
          </button>
        </div>
      </template>
    </HeaderVue>

    <div class="card border-radius">
      <div class="card-body">
        <div class="">
          <FilterBox
            :search="true"
            v-model.trim="titleInput"
            placeholder="Search Tags..."></FilterBox>
                        <div class="loader d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center" v-if="isLoading">
                <b-spinner small style="color: var(--primary-color)"></b-spinner>
                <span class="ml-2">Loading Tags</span>
              </div>
            </div>
          <div class="skeleton" v-if="isLoadingFirstTime">
            <b-skeleton-table
              class="py-3"
              :rows="5"
              :columns="4"
              :table-props="{
                bordered: true,
                striped: true,
              }"></b-skeleton-table>
          </div>
          <div class="data-table"  v-else-if="items.length>0">
            <b-table
              head-class="text-center"
              class="leadTable"
              :stickyColumn="true"
               :responsive="true"
              hover
              :fields="fields"
              :items="items">
                <template #head(checkbox)>
              <b-form-checkbox
                v-model="selectAllQuizellTags"
                size="lg"
                @input="toggleQuizellTags()"
                class="checkbox"></b-form-checkbox>
            </template>
               <template #cell(checkbox)="data">
              <b-form-checkbox
                v-model="selectedQuizellTags"
                :value="data.item.id"
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
               <template #cell(title)="data">
                <div class="d-flex align-items-center">
                 
                  <span class="text-hover-underline"  @click="$refs.EditTagModal.open({title:data.item.title,image:''})">{{ data.item.title }}</span>
                </div>
              </template>
  <template #cell(actions)="data">
    <button  class="btn" @click="()=>{
      editTagId=data.item.id;
      $refs.EditTagModal.open({title:data.item.title,image:''})
    }"><svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="pencil square" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-pencil-square b-icon bi"><g ><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path></g></svg></button>
                <!-- <b-dropdown  dropright class="" id="collection-detail">
                  <template slot="button-content">
                    <div class="p-2">
                      <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </template>
                  <b-dropdown-item class="topbarLink d-flex align-items-center" @click.stop="$refs.viewProducts.getProducts(data.item.id)">
                    View Products</b-dropdown-item
                  >
                  <b-dropdown-item class="topbarLink d-flex align-items-center">
                    Rest Product</b-dropdown-item
                  >
                </b-dropdown> -->
     </template>
            </b-table>
             </div>
             <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable :showButton="false">
<template #title>
No tag found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
            <div class="">
              <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                  :per-page="perPage"    v-if="items.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
             
            </div>
         
        </div>
      </div>
    </div>

    <!-- Shopify Modal -->
    <ModalVue
      ref="Modal"
      id="copyShopifyTags"
      title="Import from Shopify"
      size="lg">
      <div class="my-2" :class="!isLoadingSync?'d-none':'d-block'">
        <ProgressbarVue ref="progressbar"></ProgressbarVue>
       <span class="text-success text-center" style="font-size:12px;text-align:center"> Tags syncing soon, continue tasks uninterrupted. </span>
      </div>
      
  <FilterBox
              :search="true"
              placeholder="Search Tags..."
              @input="(e)=>{shopifyTagsFilterText=e}"></FilterBox>
    
              <div class="loader px-2">
         <div class="d-flex py-2" :class="[isLoadingShopifyTags?'justify-content-between':'justify-content-start']">
          <div
            class="d-flex align-items-center"
            v-if="isLoadingShopifyTags">
            <b-spinner small style="color: var(--primary-color)"></b-spinner>
            <span class="ml-2">Loading Tags</span>
          </div>
          <div class="" v-if="shopifySelectedTags.length">
            Selected Tags : {{ shopifySelectedTags.length }}
          </div>
          </div>
        </div>
      <b-skeleton-table v-if="isLoadingShopifyTagFirstTime"
  :rows="5"
  :columns="3"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table >
      <div class="data-table" v-else-if="paginatedTags.length>0">

        <div class="" style="max-height: 55vh; min-height:55vh; overflow: auto">
          <b-table
            head-class="text-center"
            class="leadTable"
            :stickyColumn="true"
            hover
            show-empty
            :fields="shopifyTagsFields"
            :items="paginatedTags">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="isLoadingShopifyTags || isLoadingSelectedShopifyTags || isLoadingSync"
                v-model="shopifySelectAllCheck"
                @change="shopifyTagSelectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item"
                v-model="shopifySelectedTags"
                :disabled="isLoadingShopifyTags || isLoadingSelectedShopifyTags || isLoadingSync"
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
            

          </b-table>
        </div>
      </div>
      <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable :showButton="false">
<template #title>
No tag found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
      <div class="d-flex justify-content-between">
        <b-pagination v-if="paginatedTags.length>0"
        class="customPagination"
          v-model="queryObject.currentPage"
          :total-rows="pageCount"
          :per-page="queryObject.perPage"
          @input="(e)=>{queryObject.currentPage=e}"></b-pagination>
          <div
            class="d-flex py-2 py-lg-0 w-100 flex-row  align-items-center justify-content-lg-end" >
           
            <BorderButton
              :custom="true"
              @click="syncSelected()" :disabled="isLoadingSync || isLoadingShopifyTags || isLoadingSelectedShopifyTags " :isLoading="isLoadingSelectedShopifyTags"   v-if="shopifySelectedTags.length"
              class="secondary-color-button mr-3"
             
              >Sync Selected</BorderButton
            >
            <button v-else :disabled="isLoadingSync || isLoadingShopifyTags || isLoadingSelectedShopifyTags " class="btn px-3 d-flex justify-content-center align-items-center py-2 shopify-btn" @click="syncAllTags()">
              <div v-if="isLoadingSync" class="d-flex align-items-center">
                  <b-spinner small ></b-spinner>
                  <span class="ml-1">Syncing...</span>
              </div>
             
              <span v-else>Sync all tags</span>
              
            </button>
          </div>
       
      </div>
    </ModalVue>
    <DeleteFooterModal ref="deleteModal" :isLoading="isLoadingDeleteTag" @click="deleteSelectedTags()" :itemLength="selectedQuizellTags.length" name="tags"
    ></DeleteFooterModal>
    <ViewCategoryProducts ref="viewProducts" category="tag"></ViewCategoryProducts>
          <AddCategory ref="EditTagModal" :editMode="true" :categoryTitle="{singular:'tag', plural:'tags'}" @submit="saveTags($event,true)" :isLoading="isLoadAddTags" ></AddCategory>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";

import BorderButton from "../Layout/BorderButton.vue";
import EmptyTable from "../Layout/EmptyTable.vue";
// import ShopifyPagination from "../Layout/ShopifyPagination.vue";
import FilterBox from "../Layout/FilterBox.vue";
import HeaderVue from "../Layout/Header.vue";
import ModalVue from "../Layout/Modal.vue";
import ProgressbarVue from "../Layout/Progressbar.vue";
import axios from "axios";
// import AddTagModal from './modal/AddTag.vue';
import DeleteFooterModal from '../Layout/DeleteFooterModal.vue';
import ViewCategoryProducts from './ViewCategoryProducts.vue';
import AddCategory from "./modal/AddCategory.vue";
import QuizellPagination from "../Layout/QuizellPagination.vue";
export default {
  components: {
    QuizellPagination,
    FilterBox,
    HeaderVue,
    EmptyTable,
    // ShopifyPagination,
    ModalVue,

    ProgressbarVue,
    BorderButton,
    // AddTagModal,
    DeleteFooterModal,
    ViewCategoryProducts,
    AddCategory
  },
  data() {
    return {
      titleInput:'',
      editTagId:null,
      isLoadAddTags:false,
      selectAllQuizellTags:false,
      selectedQuizellTags:[],
      searchTagText:'',
        currentPage: 1,
      perPage: 10,
      totalRows: 10,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: "",
        startCursor: "",
      },
      isLoadingShopifyTags: false,
      isLoadingShopifyTagFirstTime:false,
      shopifySelectAllCheck: false,
      shopifySelectedTags: [],
      fields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
        
        {
          key: "title",
          label: "Title",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass:'bg-table-heading'
        },
        
        {
          key: "actions",
          label: "Actions",
          tdClass: "align-middle data-Table d-flex justify-content-end cursor-pointer",
          thClass: "bg-table-heading text-right",
        },
      ],
      shopifyTags: [],
      shopifyTagsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
        {
          key: "title",
          label: "Tag",
          tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading',
           
        },

       
      ],
      items: [],
      shopifyTagsFilterText:'',
      isLoadingFirstTime: true,
      isLoadingSelectedShopifyTags:false,
      isLoadingDeleteTag:false,
      isLoadingSync:false,
      isLoading: false,
      queryObject: {
        currentPage: 1,
      perPage: 50,
      totalRows: 10,
      },
    };
  },
  watch: {
     items:{
      handler(newValue){
        if(newValue.every(collection=>this.selectedQuizellTags.includes(collection))&&(!newValue))
        this.selectAllQuizellTags=true;
        else
         this.selectAllQuizellTags=false;
      },
      deep:true
   },
   titleInput(){
        this.handleTagSearchDebounced()
      },
    shopifySelectedTags: {
      handler(newVal) {
        if (newVal.length > 0 && this.paginatedTags.length > 0)
          this.shopifySelectAllCheck = this.paginatedTags.every(
            (tag) => newVal.includes(tag)
          );
        else this.shopifySelectAllCheck = false;
        // newVal.length === this.shopifyTags.length;
      },
      deep: true,
    },
    paginatedTags: {
      handler(newVal) {
        const tags = newVal;
        if (
          tags.length > 0 &&
          this.shopifySelectedTags.length > 0
        )
          this.shopifySelectAllCheck = tags.every((tag) =>
            this.shopifySelectedTags.includes(tag)
          );
        else this.shopifySelectAllCheck = false;
        // this.shopifySelectAllCheck = newVal.length === this.shopifySelectedTags.length;
      },
      deep: true,
    },
    '$route.query.title'(newValue) {
      this.currentPage=1;
      this.titleInput=newValue;
      this.searchTagText=newValue
      
    },
    '$route.query.page'(newValue) {
      this.currentPage= newValue ? parseInt(newValue) : 1;
    },
    '$route.query.perPage'(newValue) {
      this.perPage= newValue ? parseInt(newValue) : 10;
    },
  },
  computed: {
    
    ...mapGetters(["getNavbarData"]),
    shopifyAllTagsIds() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.shopifyTags.map((tag) => tag.id);
    },
     pageCount() {
     
      return this.filteredTags.length
    },
  paginatedTags() {
  if (!this.filteredTags || !Array.isArray(this.filteredTags)) {
    return [];
  }
  const currentPage = parseInt(this.queryObject.currentPage);
  const perPage = parseInt(this.queryObject.perPage);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  return this.filteredTags.slice(startIndex, endIndex);
},
  filteredTags(){
     const tags=this.shopifyTags.filter(tag=>{return tag.title.toLowerCase().includes(this.shopifyTagsFilterText.toLowerCase())|| this.shopifyTagsFilterText==''})
  
     return tags
},
  },

  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.applyFilters();
    },
    updatePagination(e){
      this.currentPage=e;
      this.applyFilters();
    },
    applyFilters() {
      this.searchTagText=''
      const queryParams = {};
      if (this.titleInput) {

        queryParams.title = this.titleInput;
         this.searchTagText = this.titleInput;
      }
      queryParams.perPage=this.perPage;
      queryParams.page=this.currentPage;
      this.$router.replace({ query: queryParams });
      this.getTags()
    },
       async saveTags(data,editMode=false){
       let path = editMode?'tag/update':'tag/save'
       if(editMode) data.tag_id=this.editTagId
      this.isLoadAddTags=true
     await axios.post(path,data).then(()=>{
        this.$toasted.show("Tags saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          if(editMode)
          this.$refs.EditTagModal.close();
        else
          this.$refs.addTagModal.close();
          this.getTags();
      }).catch((error)=>{
        let {title} = error.response.data.errors
        if(title){
          this.$toasted.show(title, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else{
          this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      })
      this.isLoadAddTags=false
    },
    toggleQuizellTags(){
        if(this.selectAllQuizellTags)
       this.selectedQuizellTags =  [...new Set([...this.selectedQuizellTags, ...this.items.map(tag=>tag.id)])];
       else 
        this.selectedQuizellTags = []
    },
    async deleteSelectedTags(){
      this.isLoadingDeleteTag=true
 await axios
        .post("/tag/delete",{tag_ids: this.selectedQuizellTags})
        .then(() => {
          this.$refs.deleteModal.close();
          this.currentPage>1&&this.selectedQuizellTags==this.items?this.updatePagination(this.currentPage):this.getTags()
          this.selectedQuizellTags=[]
          this.$toasted.show("Tags deleted successfully", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        })
        .catch((e) => {
           this.$toasted.show("Error occurred while deleting tags", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          console.log(e);
        });
        this.isLoadingDeleteTag=false
    },
    
    openShopifyTagModal(){
     
       this.isLoadingShopifyTagFirstTime=true;
       this.$refs.Modal.open();
       this.getTagsFromShopify()
    },
    async syncSelected(){
      this.isLoadingSelectedShopifyTags=true
 await axios
        .post("tag/save/selected",{tags: this.shopifySelectedTags})
        .then(() => {
        
          this.$toasted.show("Tags sync successfully", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          this.$refs.Modal.close();
            this.getTags();
        })
        .catch((e) => {
           this.$toasted.show("Error occurred while syncing tags", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          console.log(e);
        });
          this.isLoadingSelectedShopifyTags=false
    },
  
    shopifyTagSelectAllToggle() {
      if(this.shopifySelectAllCheck)
       this.shopifySelectedTags =  [...new Set([...this.shopifySelectedTags, ...this.paginatedTags])];
       else  this.shopifySelectedTags = []
      
    },
    async syncAllTags() {
      this.isLoadingSync=true;
      this.progress = 100;
          setTimeout(() => {
          this.$refs.progressbar.start();
          }, 1000);
     
      await axios
        .post("/tag/sync/store")
        .then(() => {
         
           this.$refs.progressbar.stop();
          this.$toasted.show("Tags sync successfully", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          this.$refs.Modal.close();
            this.getTags('/vendor/list');
        })
        .catch((e) => {
           this.$refs.progressbar.error();
          console.log(e);
            this.$toasted.show("Error occurred while syncing tags. ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        });
       
        
         this.progress = 100;
          setTimeout(() => {
         this.isLoadingSync=false;
          }, 2000);
        
    },
    async getTagsFromShopify() {
      this.isLoadingShopifyTags = true;
      
      const data = {
        // searchQuery: `GetTags($searchQuery: String!, $first: Int!, $after: String) { tags(first: $first, query: $searchQuery, after: $after) { edges { node { id title productsCount } } pageInfo { endCursor startCursor hasNextPage hasPreviousPage } } }`,
        variables: this.queryObject,
      };
      await axios
        .post("/tag/search", data)
        .then((response) => {
          if (response.data.status == "success") {
          
            this.shopifyTags = response.data.data.map((tag)=>{return{title:tag.node}})
          }
          else{
             this.$toasted.show("Error occurred getting tags. ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingShopifyTags = false;
      this.isLoadingShopifyTagFirstTime=false;
    },
    async getTags() {
      
      this.isLoading = true;
      await axios
        .post('/tag/list',{per_page:this.perPage, page:this.currentPage, title:this.searchTagText})
        .then((response) => {
          this.items=response.data.data.data;
           this.totalRows = response.data.data.total;
          this.perPage= response.data.data.per_page
          
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingFirstTime = false;
      this.isLoading = false;
     
    },
   
    handleSearchDebounced: debounce(function () {
      this.getTagsFromShopify();
    }, 500), // 500ms debounce time, adjust as needed
     handleTagSearchDebounced: debounce(function () {
      this.applyFilters()
    }, 500),
  },
  mounted(){
   this.getTags()
  },
  created(){
    const query = this.$route.query;
    this.titleInput = this.searchTagText = query.title || '';
    this.currentPage= parseInt(query.page) || 1;
    this.perPage= parseInt(query.perPage) || 10;
  }
};
</script>
<style scoped>
.shopify-btn:active {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height: 40px;
}


#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary-color);
}
</style>
